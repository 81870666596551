@import '@angular/cdk/overlay-prebuilt.css';

@import 'primeicons/primeicons.css';

@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.snow.css';

html,
body {
  height: 100%;
  margin: 0;
  font-size: medium;
  font-family: 'Open Sans', sans-serif;
  background-color: var(--p-content-background);

  h1 {
    color: var(--p-content-color); // Fix: dark mode for header
  }
}
